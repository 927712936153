<template>
  <div id="LasVegasStrip">
    <WhereWeAreMap :mapData="mapData" />
  </div>
</template>

<script>
import WhereWeAreMap from '@/components/global/WhereWeAreMap'
import { getLasVegasStripMapData } from "@/data/sectionData/whereWeAreData/lasVegasStripMapData"

export default {
  name: "CeasarsPalace",
  components: {
    WhereWeAreMap
  },
  data() {
    return {
      mapData: getLasVegasStripMapData()
    }
  }
}
</script>

<style lang="scss scoped">

</style>